<template>
	<div class="container-fluid register">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<!-- Form -->
		<div class="row">
			<div class="col-12">
				<div class="row">
					<div class="col-md-5 col-12 m-auto">
						<form class="js-validate mt-5 pt-2" @submit.prevent="register">
							<div class="mb-4 mt-3">
								<img :src="$store.state.DefaultLogo" class="logo-icon logo" style="width: 30%; margin: 0;">
							</div>
							<!-- Title -->
							<div class="mt-3 mb-4">
								<h3 class="dark-blue-text" style="font-size: 1.2rem; font-weight: 500;">{{ $t('StartFreeTitle') }}</h3>
							</div>
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="name">{{ $t('FirstLastName') }}</label>
								<input type="text" class="form-control" @blur="validatorField('name')" id="name" :placeholder="$t('YourFullName')" required v-model="registerArray.name" />
								<small class="form-text text-danger" v-if="validatorError.name != ''">{{ validatorError.name }}</small>
								<!-- autofocus -->
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<!--div class="form-group has-error">
								<label class="form-label" for="signinSrPhone">Phone</label>
								<vue-tel-input style="border: 1px solid #d5dae2" type="text" name="phone" @onInput="onInput" v-model="phone" :validate="'min:3'" class="form-control" placeholder="Phone" aria-label="Phone"></vue-tel-input>
								<span class="help-block hidden" style="display:none;">phone</span>
							</div-->
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="phone">{{ $t('Phone') }}</label>
								<vue-tel-input :placeholder="$t('Phone')"  v-model="registerArray.phone" name="phone" id="phone" @onInput="onInput" :validate="'min:3'" required></vue-tel-input>
								<small class="form-text text-danger" v-if="validatorError.phone != ''">{{ validatorError.phone }}</small>
							</div>
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="yourEmail">{{ $t('EmailAddress') }}</label>
								<input type="email" class="form-control" id="yourEmail" @blur="validatorField('email')" 
									:placeholder="$t('YourEmailAddress')" v-model="registerArray.email" 
									required /><!-- pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
								<small class="form-text text-danger" v-if="validatorError.email != ''">{{ validatorError.email }}</small>
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="password">{{ $t('Password') }}</label>
								<div class="input-group">
									<input :type="[showPwd ? 'text' : 'password']" class="form-control" id="password" :placeholder="$t('TypeYourPassword')" @blur="validatorField('password')" required v-model="registerArray.password" />
									<div class="input-group-append">
										<a href="javascript:void(0)" class="input-group-text" id="basic-addon2" style="background-color: #fff;" @click="showPwd = !showPwd">
											<span v-if="showPwd">{{ $t('Hide') }}</span>
											<span v-else>{{ $t('Show') }}</span>
											<!--i class="fa fa-eye-slash"></i-->
										</a>
									</div>
								</div>
								<small class="form-text text-danger" v-if="validatorError.password != ''">{{ validatorError.password }}</small>
							</div>
							<!-- End Form Group -->
							
							<div class="alert alert-danger" role="alert" v-if="feedbackMessage != ''">
								<small class="form-text"> <!--i class="fa fa-exclamation-triangle"></i--> <span v-html="feedbackMessage"></span></small>
							</div>
							<!-- Button -->
							<div class="row align-items-center mb-1 d-none">
								<div class="col-12" v-if="success">
									<div class="alert alert-success" id="signup-success-alert" style="font-size: 12px;">{{ $t('WelcomeOnBoard')}}</div>
								</div>
							</div>
							<div class="row align-items-center mb-5">
								<div class="col-12 col-lg-7">
									<span class="small text-muted">{{ $t('AlreadyHaveAccount') }}</span>
									<router-link :to="{name: 'login'}" class="small">{{ $t('Login') }}</router-link>
								</div>
								<div class="col-12 col-lg-5 text-right">
									<button type="submit" class="btn btn-primary btn-block btn-getstarted">{{ $t('SignUpForFree') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button><!-- transition-3d-hover -->
								</div>
							</div>
							<!-- End Button -->
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- End Form -->
	</div>
</template>
<script>
	import { VueTelInput } from 'vue-tel-input'
	
	export default {
		data() {
			return {
				showPwd		   : false,
				registerArray  : {
					name   	   : '',
					email	   : '',
					password   : '',
					token 	   : '',
					phone	   : '',
					telCode    : '',
					country_code: '',
					timezone   : '',
					'interface': 'free'
				},
				
				// password_confirmation: '',
				validatorError	: { name:'', phone:'', email:'', password:'' },
				feedbackMessage : '',
				processing		: false,
				success			: false,
				
				sliderReview: [
					{
						id	:1,
						name:'David Somerfleck',
						selected: true,
						avatar: 'https://old.ohmylead.com/assets/v2/img/david-somerfleck.png',
						job	: 'Digital Expert at DMS - Florida',
					description: this.$t('Review1')
					},
					{
						id	:2,
						name:'Martin Belhoste',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/martin-belhoste.png',
						job	: 'Sales manager at Moët Hennessy - France',
					description: this.$t('Review2')
					},
					{
						id	:3,
						name:'Haim pekel',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/haim-pekel.png',
						job	: 'CEO at Press On it - London',
					description: this.$t('Review3')
					},
				],
				
			}
		},
		mounted() {
			//
			console.log(navigator.language)
			if( typeof( this.$route.params.token ) != "undefined" ) {
				this.registerArray.token = this.$route.params.token
			}
			this.getCountry()
			
		},
		methods: {
			
			getCountry() {
				// http://ip-api.com/json
				// https://extreme-ip-lookup.com/json/
				let that = this
				fetch('http://ip-api.com/json').then( res => res.json()).then(response => {
					// console.log(response);
					that.registerArray.country_code = response.country_code
					that.registerArray.timezone 	= response.timezone
				}).catch((/* data, status */) => {
					// console.log(data, status)
				})
			},
			
			validatorField(element) {
				// console.log(element)
				let that = this
				if( element == 'name' ) {
					that.validatorError.name = that.registerArray.name.length < 3 ? 'The name field must be at least 3 characters.' : ''
				} else if ( element == 'email' ) {
					that.validatorError.email = that.registerArray.email.length < 3 ? 'The email address is not valid.' : ''
				} else if ( element == 'password' ) {
					that.validatorError.password = that.registerArray.password.length < 8 ? 'The password field must be at least 8 characters.' : ''
				}
				
			},
			
			sliderSelect(slide) {
				
				this.sliderReview.forEach( function(item) {
					item.selected = false
				} );
				slide.selected = !slide.selected
				
			},
			
			onInput({ number, isValid, country }) {
				this.validatorError.phone = isValid ? '' : 'This phone number is not valid.'
				this.registerArray.telCode 		= '+' + country.dialCode
				this.registerArray.country_code = country.iso2
				
				
				
				const ct = require('countries-and-timezones');
				const countryInfo = ct.getCountry(country.iso2);
				if( countryInfo != null ) {
					this.registerArray.timezone = countryInfo.timezones[0]
					// console.log( countryInfo.timezones[0] )
					// console.log( countryInfo.timezones, country.iso2 )
				}
				
				
				console.log(number.input)
				// console.log(number, isValid, country)
			},
			
			register() {
				
				let that = this
				var checkEmpty = false
				that.feedbackMessage = ''
				Object.values(that.validatorError).forEach( function(item) {
					if( item != '' ) {
						that.feedbackMessage += ' - ' + item + '<br>'
						checkEmpty = true
					}
				} )
				
				if( checkEmpty ) {
					return false
				}
				
				that.processing = true
				that.registerArray.lang = navigator.language

				that.$store.dispatch('register', that.registerArray )
				.then( ({data}) => {
					
					if( data.success ) {
						// console.log( data )
						let user = data.user
						// console.log( data.data )
						that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
						if ( typeof( user.avatar ) === 'undefined' || user.avatar == null || user.avatar == '' ) {
							user.avatar = ''
						}
						that.$auth.user = (() => {
							return user
						})
						window.localStorage.setItem('oml-token', data.access_token);
						window.localStorage.setItem('oml-user', JSON.stringify(user) );
						window.localStorage.setItem('action', 'register')
						let metaData = {
							name: user.name,
							email : user.email,
							inscription :  new Date()
						}
						window.tap('customer',user.email, {meta_data : metaData});
						that.success = true
						that.$store.dispatch('clientSession').then( () => {
							// that.$router.push({name: 'newSource'})
							window.location = '/newsource' // myleads
							that.processing = false
						})
						
					} else {
						that.feedbackMessage = 'That email address has already been used'
						that.processing = false
					}
					
				})
				.catch( ( {response} ) => {
					that.feedbackMessage = ''
					let error = response.data.errors
					Object.keys(error).forEach( function( item ) {
						if(that.feedbackMessage != '') {
							that.feedbackMessage += '<br>'
						}
						that.feedbackMessage += "- " + error[item][0]
					} )
					that.processing = false
					// that.feedbackMessage = 'internal error ! Please refresh and Try again'
					// that.feedbackMessage = 'That email address has already been used'
					/* console.log(response) */
				})
				
				// console.log('qsds')
				/* var app = this
				this.$auth.register({
					data: {
						email	: app.email,
						password: app.password,
						password_confirmation: app.password_confirmation
					},
					success: function () {
						app.success = true
						this.$router.push({name: 'login', params: {successRegistrationRedirect: true}})
					},
					error: function (res) {
						// console.log(res.response.data.errors)
						app.has_error = true
						app.error = res.response.data.error
						app.errors = res.response.data.errors || {}
					}
				}) */
			}
		},
		components: {
			VueTelInput,
		},
	}
</script>

<style lang="scss">
	img.img-checkmark {
		background-color: rgba(255,255,255,.1);
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
		margin-top: -10px;
		margin-left: -10px;
	}
	.register, .login {
		.vti__dropdown-list {
			font-size: 12px;
		}
		input.form-control, input.vti__input {
			height: 45px;
			font-size: 13px;
		}
		
		button[type="submit"] {
			height: 50px;
			font-size: 16px;
		}
		
		.form-group {
			label {
				margin-bottom: 1px;
			}
		}
		
	}
</style>
