<template>
	<div class="container-fluid register p-0">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<!-- Form -->
		<div class="row">
			
			<div class="col-lg-5 col-xl-4 d-none d-lg-flex gradient-half-primary-v1" style="position: fixed; overflow-y: scroll;height: 100vh;z-index: 9;">
				<div class="w-100 p-5" style="display:flex; flex-direction: column; justify-content: space-evenly; height: 100%;">
					<div style="margin-bottom: 2rem; margin-top: 0rem;"> <!-- js-slick-carousel u-slick  -->
						<div class="slick-list draggable">
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;" v-for="(slide, key) in sliderReview" v-show="slide.selected" v-bind:key="`slideR-${key}`" @click="sliderSelect(slide)">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;" v-html="slide.description"></blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img :src="slide.avatar" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">{{ slide.name }}<br><span class="text-muted" style="font-size: .7rem;">{{ slide.job }}</span></h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
						</div>
						<ul class="js-pagination text-left u-slick__pagination mt-4 mb-0 justify-content-start" role="tablist">
							<li v-for="(slide, key) in sliderReview" :class="[ slide.selected ? 'slick-active-right slick-active slick-current' : '']" @click="sliderSelect(slide)" role="presentation" v-bind:key="`slide-${key}`">
								<span></span>
							</li>
						</ul>
					</div>
					<div class="py-3 px-2 px-sm-9 mt-3 mb-2">
						<!-- Info -->
						<ul class="list-unstyled">
							<li class="media pb-3">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('AbsolutelyFreeToTry')}}</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">{{$t('Message1')}}</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('CancelOrDowngradeAnytime')}}</h4>
									<p class="text-white-70" style="font-size: .8rem;">{{$t('Message2')}}</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark.svg" class="img-checkmark" />
								</div>
								<div class="media-body">
									<h4 class="h6 text-white mb-1">{{$t('SatisfiedOrMoneyBack')}}</h4>
									<p class="text-white-70" style="font-size: .8rem; margin-bottom: 0;">{{$t('Message1')}}</p>
								</div>
							</li>
						</ul>
						<!-- End Info -->
					</div>
					<!-- End Quote -->
				</div>
			</div>
			<div class="col-12">
				<div class="row no-gutters">
					<div class="col-12 col-xl-5 col-lg-6 offset-lg-6 offset-xl-6 space-3 space-lg-0 ohmylead">
						<form class="js-validate mt-5 pt-2" @submit.prevent="register">
							<div class="mb-4 mt-3">
								<img :src="$store.state.DefaultLogo" class="logo-icon logo" style="width: 30%; margin: 0;">
							</div>
							<!-- Title -->
							<div class="mt-3 mb-4 text-center">
								<h3 class="dark-blue-text" style="font-size: 1.2rem; font-weight: 500;">Signup for a Yearly Agency Plan and get the white label feature for free</h3>
								<!--p class="text-center">Use coupon code <b>YEARLY70</b></p-->
							</div>
							
							<div class="text-center pb-4">
								<div class="pb-4">
									Sync & Keep <b>{{ ListPlans[planInterval].leads }}</b> Leads for <b>{{ ListPlans[planInterval].price + '$' }}</b>/Year only. <!--strike>{{ ListPlans[planInterval].price + '$' }}</strike-->
								</div>
								<div>
									<span v-for="(item, id) in plansAverageList" @click="ChangePlanInterval(item)" v-bind:key="`item-${id}`" 
									:class="['btn mr-2', ListPlans[planInterval].label == item.label ? 'btn-primary' : 'btn-outline-primary']">
										{{ item.label }}
									</span>
								</div>
							</div>
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label">First & last name</label>
								<input type="text" class="form-control" @blur="validatorField('name')" placeholder="Your full name" required v-model="registerArray.name" />
								<small class="form-text text-danger" v-if="validatorError.name != ''">{{ validatorError.name }}</small>
								<!-- autofocus -->
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<!--div class="form-group has-error">
								<label class="form-label" for="signinSrPhone">Phone</label>
								<vue-tel-input style="border: 1px solid #d5dae2" type="text" name="phone" @onInput="onInput" v-model="phone" :validate="'min:3'" class="form-control" placeholder="Phone" aria-label="Phone"></vue-tel-input>
								<span class="help-block hidden" style="display:none;">phone</span>
							</div-->
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="phone">Phone</label>
								<vue-tel-input v-model="registerArray.phone" name="phone" :placeholder="$t('Phone')" id="phone" @onInput="onInput" :validate="'min:3'" required></vue-tel-input>
								<small class="form-text text-danger" v-if="validatorError.phone != ''">{{ validatorError.phone }}</small>
							</div>
							<!-- End Form Group -->
							
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="yourEmail">Email address</label>
								<input type="email" class="form-control" id="yourEmail" @blur="validatorField('email')" 
									placeholder="Your work email" v-model="registerArray.email" 
									required /><!-- pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
								<small class="form-text text-danger" v-if="validatorError.email != ''">{{ validatorError.email }}</small>
							</div>
							<!-- End Form Group -->
							<!-- Form Group -->
							<div class="js-form-message form-group">
								<label class="form-label" for="password">Password</label>
								<div class="input-group">
									<input :type="[showPwd ? 'text' : 'password']" class="form-control" id="password" placeholder="Type Your Password" @blur="validatorField('password')" required v-model="registerArray.password" />
									<div class="input-group-append">
										<a href="javascript:void(0)" class="input-group-text" id="basic-addon2" style="background-color: #fff;" @click="showPwd = !showPwd">
											<span v-if="showPwd">Hide</span>
											<span v-else>Show</span>
											<!--i class="fa fa-eye-slash"></i-->
										</a>
									</div>
								</div>
								<small class="form-text text-danger" v-if="validatorError.password != ''">{{ validatorError.password }}</small>
							</div>
							<!-- End Form Group -->
							
							<div class="alert alert-danger" role="alert" v-if="feedbackMessage != ''">
								<small class="form-text"> <!--i class="fa fa-exclamation-triangle"></i--> <span v-html="feedbackMessage"></span></small>
							</div>
							<!-- Button -->
							<div class="row align-items-center mb-5">
								<div class="col-12 col-lg-7">
									<span class="small text-muted">Already have an account ? </span>
									<router-link :to="{name: 'login'}" class="small">Login</router-link>
								</div>
								<div class="col-12 col-lg-5 text-right">
									<button type="submit" class="btn btn-primary btn-block btn-getstarted">Get Started <i class="fa fa-spinner fa-spin" v-show="processing"></i></button><!-- transition-3d-hover -->
								</div>
							</div>
							<!-- End Button -->
						</form>
					</div>
				</div>
				<div class="row d-lg-none">
					<div class="col-12">
						<div class="js-slick-carousel u-slick mobile-testimonials" style="margin-bottom: 2rem; margin-top: 0rem; display:none;">
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px; background-color: #377cff;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('review1')}}
									</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/david-somerfleck.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">David Somerfleck<br><span class="text-muted" style="font-size: .7rem;">Digital Expert at DMS - Florida</span></h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('review2')}}
									</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/martin-belhoste.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">
											Martin Belhoste<br><span class="text-muted" style="font-size: .7rem;">Sales manager at Moët Hennessy - France</span>
										</h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
							<!-- Testimonial -->
							<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;">
								<div class="text-left mb-0">
									<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;">{{$t('review3')}}</blockquote>
								</div>
								<div class="d-flex justify-content-start align-items-center mt-2">
									<div class="d-flex align-items-center justify-content-start">
										<img src="https://old.ohmylead.com/assets/v2/img/haim-pekel.png" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
										<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">
											Haim pekel<br><span class="text-muted" style="font-size: .7rem;">CEO at Press On it - London</span>
										</h4>
									</div>
								</div>
							</div>
							<!-- End Testimonial -->
						</div>
						
						<div class="mobile-testimonials" style="margin-bottom: 2rem; margin-top: 0rem;"> <!-- js-slick-carousel u-slick  -->
							<div class="slick-list draggable">
								<!-- Testimonial -->
								<div class="js-slide light-bg py-3 px-4" style="border-radius: 7px;" v-for="(slide, key) in sliderReview" v-show="slide.selected" v-bind:key="`sld-${key}`">
									<div class="text-left mb-0">
										<blockquote class="h4 text-lh-md" style="color: #377cff; font-size: .8rem !important;" v-html="slide.description"></blockquote>
									</div>
									<div class="d-flex justify-content-start align-items-center mt-2">
										<div class="d-flex align-items-center justify-content-start">
											<img :src="slide.avatar" class="mr-2" style="border-radius: 50%; width: 2.5rem;">
											<h4 class="h6 mb-0 text-white-70" style="font-size: .7rem; color: #2b334a;">{{ slide.name }}<br><span class="text-muted" style="font-size: .7rem;">{{ slide.job }}</span></h4>
										</div>
									</div>
								</div>
								<!-- End Testimonial -->
							</div>
							<ul class="js-pagination text-left u-slick__pagination mt-4 mb-0 justify-content-start" style="" role="tablist">
								<li v-for="(slide, key) in sliderReview" :class="[ slide.selected ? 'slick-active-right slick-active slick-current' : '']" @click="sliderSelect(slide)" role="presentation" v-bind:key="`sl-${key}`">
									<span></span>
								</li>
							</ul>
						</div>
						
						<ul class="list-unstyled">
							<li class="media pb-3 d-none">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">Absolutely FREE to try</h4>
									<p class="" style="font-size: .8rem; margin-bottom: 0;">No catch. See it for yourself for 7 days absolutely free.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3 d-none"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">Cancel or Downgrade anytime</h4>
									<p class="" style="font-size: .8rem;">You can upgrade, downgrade or cancel your subscription anytime. No questions asked.</p>
								</div>
							</li>
							<li class="border-top opacity-md py-3"></li>
							<li class="media">
								<div class="btn btn-icon btn-xs btn-soft-white rounded-circle mt-1 mr-3 d-inline" style="background: #f0f8ff;">
									<img src="https://old.ohmylead.com/assets/v2/img/checkmark-blue.svg">
								</div>
								<div class="media-body">
									<h4 class="h6 mb-1">Satisfied or Money Back</h4>
									<p class="" style="font-size: .8rem; margin-bottom: 0;">Get your money back in less than 24h, after using our software for 30 days.</p>
								</div>
							</li>
						</ul>
						<!-- End Clients Section -->
						<!--a href="/register" class="btn btn-block" style="background-color: #f0f8ff; color: #377dff; margin-bottom: 2rem; font-size: .9rem;">Go up <img style="width: 20px;" src="https://old.ohmylead.com/assets/v2/img/arrow-up.svg"></a-->
					</div>
				</div>
			</div>
		</div>
		<!-- End Form -->
	</div>
</template>
<script>
	import { VueTelInput } from 'vue-tel-input'
	
	export default {
		data() {
			return {
				showPwd		   : false,
				registerArray  : {
					name   	   : '',
					email	   : '',
					password   : '',
					token 	   : '',
					phone	   : '',
					telCode    : '',
					country_code: '',
					timezone   : '',
					interface : 'agency_cc'
				},
				
				// password_confirmation: '',
				validatorError	: { name:'', phone:'', email:'', password:'' },
				feedbackMessage : '',
				processing		: false,
				
				
				plansAverageList : [],
				ListPlans	: {
					"yearly": { id: 2, label: '500', leads: 500, price: '', product_id: '', cost: '' },
				},
				planInterval: 'yearly',
				
				
				sliderReview: [
					{
						id	:1,
						name:'David Somerfleck',
						selected: true,
						avatar: 'https://old.ohmylead.com/assets/v2/img/david-somerfleck.png',
						job	: 'Digital Expert at DMS - Florida',
						description:this.$t('Review1')
					},
					{
						id	:2,
						name:'Martin Belhoste',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/martin-belhoste.png',
						job	: 'Sales manager at Moët Hennessy - France',
						description:this.$t('Review2')
					},
					{
						id	:3,
						name:'Haim pekel',
						selected: false,
						avatar: 'https://old.ohmylead.com/assets/v2/img/haim-pekel.png',
						job	: 'CEO at Press On it - London',
						description:this.$t('Review3')
					},
				],
				
			}
		},
		mounted() {
			//
			if( typeof( this.$route.params.token ) != "undefined" ) {
				this.registerArray.token = this.$route.params.token
			}
			this.getCountry()
			this.LoadPlans()
		},
		methods: {
			
			sliderSelect(slide) {
				
				this.sliderReview.forEach( function(item) {
					item.selected = false
				} );
				slide.selected = !slide.selected
				
			},
			
			LoadPlans() {
                
				let that  = this
				let url   = '/auth/plan/listyear'
				let method= 'POST'
				let data  = {}
				
				that.$http({ url: url, method: method, data: data }).then((response) => {
					let plans = response.data.plans
					plans.forEach( function(plan) {
						if( plan.name == process.env.VUE_APP_PLAN_FULL && plan.plan_type == "year" ) {
							// let cost_to_pay = (parseInt( plan.price  ) * 50) / 100
							let cost_to_pay = parseInt( plan.price )
							that.plansAverageList.push({
								id: plan.id, label: plan.total_leads.replace(/000$/, 'k'), leads: plan.total_leads, price: plan.price, product_id: plan.product_id, cost: cost_to_pay
							})
							if (plan.total_leads == 2500) {
								that.ListPlans[that.planInterval].id 	= plan.id
								that.ListPlans[that.planInterval].label = plan.total_leads.replace(/000$/, 'k')
								that.ListPlans[that.planInterval].leads = plan.total_leads
								that.ListPlans[that.planInterval].price = plan.price
								that.ListPlans[that.planInterval].product_id = plan.product_id
								that.ListPlans[that.planInterval].cost  = cost_to_pay
							}
						}
					} )
					
				}).catch(( response ) => {
					console.log(response)
				})
				
            },
			
			ChangePlanInterval(interval) {
				let that = this
				
				that.ListPlans[that.planInterval].id 	= interval.id
				that.ListPlans[that.planInterval].label = interval.label
				that.ListPlans[that.planInterval].leads = interval.leads
				that.ListPlans[that.planInterval].price = interval.price
				that.ListPlans[that.planInterval].cost  = interval.cost
				that.ListPlans[that.planInterval].product_id = interval.product_id
				
			},
			
			getCountry() {
				// http://ip-api.com/json
				// https://extreme-ip-lookup.com/json/
				let that = this
				fetch('http://ip-api.com/json').then( res => res.json()).then(response => {
					// console.log(response);
					that.registerArray.country_code = response.country_code
					that.registerArray.timezone 	= response.timezone
				}).catch((/* data, status */) => {
					// console.log(data, status)
				})
			},
			
			validatorField(element) {
				// console.log(element)
				let that = this
				if( element == 'name' ) {
					that.validatorError.name = that.registerArray.name.length < 3 ? 'The name field must be at least 3 characters.' : ''
				} else if ( element == 'email' ) {
					that.validatorError.email = that.registerArray.email.length < 3 ? 'The email address is not valid.' : ''
				} else if ( element == 'password' ) {
					that.validatorError.password = that.registerArray.password.length < 8 ? 'The password field must be at least 8 characters.' : ''
				}
			},
			
			onInput({ number, isValid, country }) {
				this.validatorError.phone = isValid ? '' : 'This phone number is not valid.'
				this.registerArray.telCode 		= '+' + country.dialCode
				this.registerArray.country_code = country.iso2
				
				const ct = require('countries-and-timezones');
				const countryInfo = ct.getCountry(country.iso2);
				if( countryInfo != null ) {
					this.registerArray.timezone = countryInfo.timezones[0]
				}
				
				console.log(number.input)
			},
			
			CheckoutPlan() {
				let that = this
				// let cost 	= that.ListPlans[that.planInterval].cost
				let product_id	= that.ListPlans[that.planInterval].product_id
				let email		= that.registerArray.email
				
				window.Paddle.Checkout.open({
					product			: product_id,
					passthrough		: email,
					email			: email,
					// coupon   		: 'NEWYEAR2021',
					customData: {
						tolt_referral: window.tolt_referral,
					},
					allowQuantity	: false,
					disableLogout:true,
					successCallback	: function() {
						
						that.$store.dispatch('clientSession').then( () => {
							// that.$router.push({name: 'newsource'})
							window.location = '/newsource' // myleads
							that.processing = false
						})
						
					},
					closeCallback : function() {
						// that.$router.push({name: 'login'})
						window.localStorage.clear()
					},
				})
			},
			
			register() {
				
				let that = this
				var checkEmpty = false
				that.feedbackMessage = ''
				Object.values(that.validatorError).forEach( function(item) {
					if( item != '' ) {
						that.feedbackMessage += ' - ' + item + '<br>'
						checkEmpty = true
					}
				} )
				
				if( checkEmpty ) {
					return false
				}
				
				that.processing = true
				that.registerArray.lang = navigator.language
				that.$store.dispatch('register', that.registerArray ).then( ({data}) => {
					if( data.success ) {
						// console.log( data )
						let user = data.user
						// console.log( data.data )
						that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
						if ( typeof( user.avatar ) === 'undefined' || user.avatar == null || user.avatar == '' ) {
							user.avatar = ''
						}
						that.$auth.user = (() => {
							return user
						})
						window.localStorage.setItem('oml-token', data.access_token);
						window.localStorage.setItem('oml-user', JSON.stringify(user) );
						window.localStorage.setItem('action', 'register')
						let metaData = {
							name: user.name,
							email : user.email,
							inscription :  new Date()
						}
						window.tap('customer',user.email, {meta_data : metaData});
						that.CheckoutPlan()
						
					} else {
						that.feedbackMessage = 'That email address has already been used'
						that.processing = false
					}
					
				}).catch( ( {response} ) => {
					that.feedbackMessage = ''
					let error = response.data.errors
					Object.keys(error).forEach( function( item ) {
						if(that.feedbackMessage != '') {
							that.feedbackMessage += '<br>'
						}
						that.feedbackMessage += "- " + error[item][0]
					} )
					that.processing = false
					// that.feedbackMessage = 'internal error ! Please refresh and Try again'
					// that.feedbackMessage = 'That email address has already been used'
				})
				
				// console.log('qsds')
				/* var app = this
				this.$auth.register({
					data: {
						email	: app.email,
						password: app.password,
						password_confirmation: app.password_confirmation
					},
					success: function () {
						app.success = true
						this.$router.push({name: 'login', params: {successRegistrationRedirect: true}})
					},
					error: function (res) {
						// console.log(res.response.data.errors)
						app.has_error = true
						app.error = res.response.data.error
						app.errors = res.response.data.errors || {}
					}
				}) */
			}
			
		},
		components: {
			VueTelInput,
		},
	}
</script>

<style lang="scss">
	img.img-checkmark {
		background-color: rgba(255,255,255,.1);
		width: 1.5rem;
		height: 1.5rem;
		border-radius: 100%;
		margin-top: -10px;
		margin-left: -10px;
	}
	.register, .login {
		.vti__dropdown-list {
			font-size: 12px;
		}
		input.form-control, input.vti__input {
			height: 45px;
			font-size: 13px;
		}
		
		button[type="submit"] {
			height: 50px;
			font-size: 16px;
		}
		
		.form-group {
			label {
				margin-bottom: 1px;
			}
		}
		
	}
</style>
