<template>
	<div class="col-md-12 login">
		
		<meta name="viewport" content="width=device-width,initial-scale=1.0">
		<meta name="viewport" content="width=500, initial-scale=1">
		<meta name="viewport" content="initial-scale=1, maximum-scale=1">
		
		<div class="row">
			<div class="col-12 col-md-8 col-lg-6 mx-auto">
				<div class="login_left-side login_bouless">
					<div class="login_left-side-body">
						<div class="logo text-center" style="margin-left: auto; margin-right: auto;">
							<router-link :to="{name: 'home'}"><img :src="$store.state.DefaultLogo" style="width: 230px;" alt="" /></router-link>
						</div>
						
						<div class="text-center" style="color: #2b334a; margin-bottom: 30px;" v-if="inProcess">
							<h4>{{ $t('title_forget') }}</h4>
							<p><router-link :to="{name: 'home'}" class="back_login">{{ $t('BackToHome') }}</router-link></p>
						</div>
						<div class="text-center" v-else>
							<h4>{{ $t('ForgotYourPassword') }}</h4>
							<p>{{ $t('ForgotPasswordTitle') }}</p>
							<p><router-link :to="{name: 'login'}" class="back_login">{{ $t('BackToLogin') }}</router-link></p>
						</div>
						
						<div class="login_form" style="padding: 0 1rem;">
							<div class="row">
								<div class="col-md-2 col-sm-12 col-12"></div>
								<div class="col-md-8 col-sm-12 col-12">
									<div class="row">
										<div class="col-md-12" v-show="!inProcess">
											<form autocomplete="on" @submit.prevent="forgot" method="post">
												<div class="form-group"><!-- has-error -->
													<input type="email" class="form-control" placeholder="Enter Email"
													name="email" v-model="email" required autofocus pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$" />
												</div>
												<span class="help-block" v-if="has_error">
													<strong>{{ message }}</strong>
												</span>
												<!--input type="submit" class="btn-login" value="Login to your account"-->
												<button type="submit" class="btn-login">{{ $t('Send') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button>
											</form>
										</div>
										
									</div>
									<div class="row">
										<div class="col-12">
											<div class="meta" style="border: 0">
												<router-link :to="{name: 'register'}" class="no-account pull-left">{{ $t('CheckRegister') }}</router-link>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-2 col-sm-12 col-12"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>
<script>
	export default {
		data() {
			return {
				inProcess 	: false,
				has_error 	: false,
				processing 	: false,
				email		: '',
				message		: ''
			}
		},
		mounted() {
			//
		},
		methods: {
			forgot() {
				// get the redirect object
				var that = this
				that.has_error = false
				that.inProcess = false
				that.processing = true
				this.$http({
					url: '/auth/password/create',
					method: 'POST',
					data: {email:that.email,lang:navigator.language}
				})
				.then((res) => {
					that.message = res.data.message
					if( res.data.success || res.data.status == '200' ) {
						that.inProcess = true
						/* this.$router.push({name: 'reset'}) */
					} else {
						that.has_error = true
					}
					that.processing = false
				}, ({response}) => {
					// console.log(response.data.message)
					that.processing = false
					that.has_error = true
					that.message	= response.data.message
				})
				
			}
		}
	}
</script>