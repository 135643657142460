<template>
	<div class="col-md-12 login">
		<section class="hbox stretch">
			<div class="logins_pages">
				<div class="row">
					
					<div class="col-md-3"></div>
					<div class="col-md-6"> <!--  col-md-offset-3 -->
						<div class="login_left-side login_bouless">
							<div class="login_left-side-body">
								<div class="logo text-center" style="margin-left: auto; margin-right: auto;">
									<router-link :to="{name: 'home'}"><img :src="$store.state.DefaultLogo" style="width: 230px;" alt="" /></router-link>
								</div>
								<div class="login_text-heading text-center" style="color: #2b334a; margin-bottom: 30px;">
									<h4>{{ $t('login_title') }}</h4>
								</div>
								
								<div class="login_form" style="padding: 0 1rem;">
									<div class="row">
										<div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
											<div class="row">
												<div class="col-md-12">
													<form autocomplete="on" @submit.prevent="GeneratePassword" method="post">
														<div class="form-group"><!-- has-error -->
															<label class="text-left" for="email">{{ $t('Email') }}</label>
															<input type="text" name="email" id="email" class="form-control" placeholder="Email Address" v-model="email" required autofocus pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" />
														</div>
														<!--input type="submit" class="btn-login" value="Login to your account"-->
														<button type="submit" class="btn-login">{{ $t('Send') }} <i class="fa fa-spinner fa-spin" v-show="processing"></i></button>
														
														<div :class="['alert', is_success ? 'alert-success' : 'alert-danger']" v-if="message != ''">{{ message }}</div>
														
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				email		: null,
				token		: '',
				is_success	: false,
				processing	: false,
				message		: ''
			}
		},
		mounted() {
			//
			if( typeof( this.$route.params.token ) != "undefined" ) {
				this.token = this.$route.params.token
			}
		},
		methods: {
			GeneratePassword () {
				
				let that = this
				that.is_success = false
				that.processing = true
				let email 	 	= that.email 
				that.$store.dispatch('PasswordGen', { email:email, provider : "system user",lang : navigator.language }).then(({data}) => {
					console.log(data)
					that.is_success = data.success
					that.message 	= data.message
					that.processing = false
				}).catch(() => {
					that.processing = false
				})
				
			},
		}
	}
</script>
<style>
	
</style>